import React, { useEffect, useState } from 'react';
import { HeadTitle } from "../common/HeadTitle/PageTitle";
import { ApplicationInfoItem } from "./ApplicationInfoItem/ApplicationInfoItem";
import { DividingLine } from "../common/DividingLine/DividingLine";
import { observer } from "mobx-react-lite";
import { ApplicationsStore } from "../../store/applications/Applications";
import { Fallback } from "../helpers/Fallback/Fallback";
import { formatIsoToDate, formatPhoneByPattern } from "../helpers/utils";
import { DeleteModal } from "../helpers/Modals/DeleteModal";
import { openNotification } from "../helpers/notification";
import { Link, useHistory } from "react-router-dom";
import { ListItem } from "@material-ui/core";
import { DelegationModal } from "../helpers/Modals/DelegationModal/DelegationModal";
import {
    ChangeApplicationStatusModal
} from "../helpers/Modals/ChangeApplicationStatusModal/ChangeApplicationStatusModal";
import { SimpleTextArea } from "../helpers/Inputs/Textarea";
import { SubPageItem } from "./SubPageItem/SubPageItem";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../redux/store";
import { IInitialPage } from "../../store/extraPage/types";
import { HistoryTableContainer } from "./HistoryTable/HistoryTable";
import { getTaskActions, getTaskHistory } from "../../redux/reducers/applications/actions/actions";
import { ActionsTableContainer } from "./ActionsTable/ActionsTable";
import { RangeDatePicker } from "../NewApplication/DatePicker/DatePickerComponent";
import { DateRange } from "@material-ui/icons";
import { Moment } from "moment";
import { ROUTE_PATH } from '../../routers/paths.main';
import { setAppOrTemplateFlag, setApplicationId } from '../../redux/reducers/directory/actions/actions';


const s = require('./style.module.scss');

interface IApplicationInfoComponentProps {
    headTitle?: string,
    dateOfCreation?: string,
    description?: string,
    organization?: string,
    contactPerson?: string,
    phone?: string,
    address?: string,
    remoteNumber?: string,
    source?: string,
    executor?: string,
    observers?: string,
    status?: string,
    term?: string,
    tags?: [],
    attachments?: [],

    [key: string]: any
}

export const ApplicationInfo: React.FC<IApplicationInfoComponentProps> = observer(({
    phone,
    status,
    description,
    address,
    source,
    attachments,
    contactPerson,
    dateOfCreation,
    executor,
    observers,
    organization,
    remoteNumber,
    tags,
    term,
    headTitle,
}) => {

    const isMobile: boolean = window.outerWidth <= 425

    //data and instruments

    const router = useHistory()
    const dispatch = useDispatch()

    const {
        applicationId,
        requestApplicationInfo,
        applicationsInfoLoading,
        applicationInfo,
        requestIudApplication,
        appStatuses,
        requestTaskComments,
        commentsLoading,
        commentsList,
        requestSendComment,
        requestSelPults,
        pults
    } = ApplicationsStore


    const [deligateMode, setDeligateMode] = useState<boolean>(false)
    const [deleteMode, setDeleteMode] = useState<boolean>(false)
    const [endTaskMode, setEndTaskMode] = useState<boolean>(false)
    const [buttonsNumber, setButtonsNumber] = useState<number>(0)
    const [panel, setPanel] = useState<string>('')
    const [actionButton, setActionButton] = useState<string>('')

    const [newComment, setNewComment] = useState<string>('')

    const initialPages = useSelector((state: TRootState) => state.applicationsReducer.initialPages)

    const [subPage, setSubPage] = useState<IInitialPage>(initialPages.find(i => i.isActive) || initialPages[0])

    const [flag, setFlag] = useState<string>('')
    //data for task actions
    const [searchValue, setSearchValue] = useState<string>('')
    const [actionDateFrom, setActionDateFrom] = useState<string>('')
    const [actionDateTo, setActionDateTo] = useState<string>('')

    const [applicationIdState, setApplicationIdState] = useState<any>(applicationId)

    const requestTaskActionsHandler = () => {
        dispatch(getTaskActions({
            orderById: 1,
            searchValue,
            offset: 0,
            count: 10,
            dateTo: actionDateTo !== '' ? actionDateTo : undefined,
            dateFrom: actionDateFrom !== '' ? actionDateFrom : undefined,
            id_system: applicationInfo?.system?.id,
            id_device: applicationInfo?.device?.id
        }))
    }

    //useEffects
    useEffect(() => {
        let array = window.location.href.split('/')
        if (applicationIdState || array[4]) {
            requestApplicationInfo(+array[4])
            requestTaskComments({
                offset: 0,
                count: 10,
                id_task: +array[4]
            })
            requestSelPults()
        }


    }, [])

    useEffect(() => {
        /*if (applicationIdState) {
            switch (subPage.name) {
                case 'История': {
                    dispatch(getTaskHistory({
                        taskId: applicationIdState
                    }))
                    return
                }
                default:
                    return
            }
        }*/
    }, [subPage])
    //functions
    const sortButtonHandler = (btnName: string) => {
        switch (btnName) {
            case 'Завершить':
                setEndTaskMode(true)
                return
            case 'Делегировать':
                setDeligateMode(true)
                return
            default:
                return;
        }
    }

    useEffect(() => {
        setSubPage(initialPages.find(i => i.isActive) || initialPages[0])
    }, [initialPages])

    //console.log(JSON.parse(JSON.stringify(applicationInfo?.actionButtons)))


    useEffect(() => {
        pults?.map(element => {
            if (element.panel === applicationInfo?.device?.panel) {
                setPanel(element.name)
            }
        })
    }, [applicationInfo?.device?.panel, pults])

    const editHandler = () => {
        const id = window.location.href.split('/')
        dispatch(setAppOrTemplateFlag('editApp'))
        dispatch(setApplicationId(+id[4]))
    }

    const onClickSendAction = async (name: string) => {
        const id = window.location.href.split('/')
        switch (name) {
            case 'Взять в работу':
                await requestIudApplication({
                    flag: 'status',
                    id: +id[4],
                    status: { id: 3 }
                }, () => {
                    openNotification({
                        type: 'success',
                        message: 'Заявка успешно взята в работу'
                    })
                }).then(() => {
                    requestApplicationInfo(+id[4])
                })
                break;

            default:
                break;
        }
    }

    //console.log(JSON.parse(JSON.stringify(applicationInfo?.actionButtons)))

    useEffect(() => {
        const href = window.location.href.split('/')[window.location.href.split('/').length - 1]
        setFlag(href)
    }, [flag])

    useEffect(() => {
        const href = window.location.href.split('/')[window.location.href.split('/').length - 2]
        setApplicationIdState(applicationIdState ? applicationIdState : href)
    }, [applicationIdState])

    return (
        <div className={s.main_page_wrap}>
            {applicationsInfoLoading
                ? <Fallback />
                : applicationInfo && <div>
                    {/*<Header/>*/}

                    <div className={s.title_wrap}>
                        <HeadTitle needBack title={`${applicationInfo?.name}`}
                        // title={headTitle}
                        /> {/*TODO: headTitle from props*/}

                    </div>
                    <div className={s.date_wrap}>
                        {applicationInfo?.dateCreate && formatIsoToDate(applicationInfo?.dateCreate)}
                    </div>
                    <div className={s.content_wrap}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '50%' }}>
                                <div className={s.params_wrap}>Администратор</div>
                                <div className={s.description_wrap}>
                                    {applicationInfo?.admin?.name || '-'}
                                    {/*<span className={s.expand_btn}>
                            Развернуть
                            </span>*/}
                                </div>
                                <div>
                                    <ApplicationInfoItem title={'Номер заявки'} data={`${applicationInfo?.number}`} />
                                    {/* <ApplicationInfoItem title={'Тип системы'} data={`${applicationInfo?.system?.name}`} /> */}
                                    <ApplicationInfoItem title={'Описание'}
                                        data={applicationInfo?.description ? `${applicationInfo?.description}` : '-'} />
                                    <ApplicationInfoItem title={'Организация'}
                                        data={applicationInfo?.device?.name ? `${applicationInfo?.device?.name}` : '-'} />
                                    <ApplicationInfoItem title={'Контактное лицо'} data={`${applicationInfo?.client?.name}`} />
                                    <ApplicationInfoItem title={'Телефон контактного лица'} data={applicationInfo?.client?.phone?.value ? formatPhoneByPattern(applicationInfo?.client?.phone?.value) : '-'}
                                        colorData={'#0275D8'} />
                                    <ApplicationInfoItem title={'Адрес'} data={`${applicationInfo?.client?.address}`}
                                        colorData={'#0275D8'} />
                                    <ApplicationInfoItem title={'Тип системы'} data={`${panel}`} />
                                    <ApplicationInfoItem title={'Пультовый номер'}
                                        data={`${applicationInfo?.device?.number ? applicationInfo?.device?.number : ''}`} />
                                    <ApplicationInfoItem title={'Количество тревог за последние 30 дней'}
                                        data={`${applicationInfo?.alarmCountLastMonth ? applicationInfo?.alarmCountLastMonth : '0'}`} />
                                </div>
                            </div>
                            <DividingLine marginTop={'31px'} />
                            <div style={{ width: '50%' }}>
                                <ApplicationInfoItem title={'Источник'}
                                    data={applicationInfo?.source ? `${applicationInfo?.source?.name}` : '-'} />
                                <ApplicationInfoItem title={'Исполнитель'}
                                    data={applicationInfo?.responsible ? applicationInfo?.responsible : '-'} />
                                <ApplicationInfoItem title={'Наблюдатели'}
                                    data={applicationInfo?.observers && applicationInfo?.observers?.length > 0 ? applicationInfo?.observers : '-'} />
                                <ApplicationInfoItem title={'Статус'}
                                    data={applicationInfo?.status ? `${applicationInfo?.status?.name}` : '-'} />
                                <ApplicationInfoItem title={'Категория'}
                                    data={applicationInfo?.category?.name ? `${applicationInfo?.category?.name}` : '-'} />
                                <ApplicationInfoItem title={'Дата создания'}
                                    data={`${applicationInfo?.dateCreate && formatIsoToDate(applicationInfo?.dateCreate) + ' ' + applicationInfo?.dateCreate[11] + applicationInfo?.dateCreate[12] + applicationInfo?.dateCreate[13] + applicationInfo?.dateCreate[14] + applicationInfo?.dateCreate[15]}`} />
                                <ApplicationInfoItem title={'Дата редактирования'}
                                    data={`${applicationInfo?.dateEdited ? formatIsoToDate(applicationInfo?.dateEdited) + ' ' + applicationInfo?.dateEdited[11] + applicationInfo?.dateEdited[12] + applicationInfo?.dateEdited[13] + applicationInfo?.dateEdited[14] + applicationInfo?.dateEdited[15] : '-'}`} />
                                <ApplicationInfoItem title={'Срок'}
                                    data={`${applicationInfo?.date?.value && formatIsoToDate(applicationInfo?.date?.value) + ' ' + applicationInfo?.date?.value[11] + applicationInfo?.date?.value[12] + applicationInfo?.date?.value[13] + applicationInfo?.date?.value[14] + applicationInfo?.date?.value[15]}`}
                                    colorData={'#FA5252'} />
                                <ApplicationInfoItem title={'Метки'}
                                    data={applicationInfo?.tags && applicationInfo?.tags?.length > 0 ? applicationInfo?.tags : 'Нет данных'}
                                    colorData={applicationInfo?.tags && applicationInfo?.tags?.length > 0 ? `${applicationInfo?.tags.map(i => {
                                        return `${i.color}`
                                    })}` : '-'} />
                                <ApplicationInfoItem title={'Файлы'}
                                    data={applicationInfo.content ? applicationInfo.content : 'Нет данных'} />
                            </div>
                        </div>

                        {/*<ApplicationInfoItem title={'Вложения'} data={'Скан-договора.jpg Инструкция.pdf'}
                                             colorData={'#0275D8'}/>*/}
                        {/*<DividingLine marginTop={'31px'}/>*/}

                        {
                            flag !== 'archive'  &&

                            <>

                                <DividingLine marginTop={'31px'} />

                                <div className={s.footer_btn_wrap}>
                                    {applicationInfo?.buttons?.map((value, index) =>
                                        <ListItem
                                            button
                                            style={{
                                                color: value.color || 'white',
                                                width: value.name !== 'Завершить' ? 130 : 100,
                                                background: value.background || 'green',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: 4
                                            }}
                                            onClick={() => {
                                                sortButtonHandler(value.name)
                                                setButtonsNumber(index)
                                                onClickSendAction(value.name)
                                            }}
                                            title={value.name}>{value.name}</ListItem>)}

                                    {applicationInfo?.actionButtons?.find(item => item.formview.mnemo === 'delete_task') &&
                                        <ListItem
                                            button
                                            style={{
                                                color: '#fff',
                                                background: '#FA5252',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: 88,
                                                borderRadius: 4
                                            }}
                                            onClick={() => setDeleteMode(true)}
                                            title={'Удалить'}>Удалить</ListItem>}

                                    {applicationInfo?.actionButtons?.find(item => item.formview.mnemo === 'edit_task') &&
                                        <Link to={ROUTE_PATH.newApplication}>
                                            <ListItem
                                                button
                                                onClick={editHandler}
                                                style={{
                                                    color: '#fff',
                                                    background: '#000000',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: 120,
                                                    borderRadius: 4,
                                                    marginLeft: 8
                                                }}
                                                title={'Редактировать'}>Редактировать</ListItem>
                                        </Link>}

                                        <ListItem
                                            button
                                            onClick={() => window.history.back()}
                                            style={{
                                                color: '#fff',
                                                background: '#000000',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: 120,
                                                borderRadius: 4,
                                                marginLeft: 8
                                            }}
                                            title={'Редактировать'}>Назад</ListItem>
                                </div>
                            </>
                        }



                        {deligateMode && <DelegationModal
                            title={'Делегировать заявку'}
                            open={deligateMode}
                            appId={applicationInfo ? applicationInfo.id : 0}
                            closeHandler={() => setDeligateMode(false)}
                        />}
                        <DeleteModal
                            title={'данной заявки'}
                            open={deleteMode}
                            closeHandler={() => setDeleteMode(false)}
                            request={() => {
                                applicationIdState && requestIudApplication({
                                    id: +applicationIdState,
                                    flag: 'D',
                                }, () => {
                                    openNotification({
                                        type: 'success',
                                        message: 'Заявка успешно удалена'
                                    })
                                    router.push('/applications')
                                })
                            }}
                        />
                        {endTaskMode && <ChangeApplicationStatusModal
                            closeHandler={() => setEndTaskMode(false)}
                            open={endTaskMode}
                            title={'Завершение заявки'}
                            appId={applicationIdState ? applicationIdState : 0}
                            statuses={applicationInfo?.buttons[buttonsNumber].actions || []}
                        />}
                    </div>
                    <div className={s.comments}>
                        <div className={s.subPages}>
                            {initialPages.map(i => <SubPageItem item={i}
                                taskHistoryCount={applicationInfo?.taskHistoryCount || 0}
                                taskActionsCount={applicationInfo?.taskEventsCount || 0}
                                commentsCount={applicationInfo?.taskCommentsCount || 0} />)}
                        </div>
                        {subPage.id === 0 && <div className={s.comments_block}>
                            {
                                commentsLoading
                                    ? <Fallback />
                                    : commentsList?.comments && commentsList?.comments.length > 0 && commentsList?.comments.map(comment =>
                                        <div className={s.item}>
                                            <div className={s.title}>
                                                <div className={s.author}>
                                                    {comment?.user?.name}
                                                </div>
                                                <div className={s.date}>
                                                    {comment?.date ? formatIsoToDate(comment.date) + ' ' + comment.date[11] + comment.date[12] + comment.date[13] + comment.date[14] + comment.date[15] : ''}
                                                </div>
                                            </div>
                                            <div className={s.content}>
                                                {comment?.name || 'Пустой комментарий'}
                                            </div>
                                        </div>)
                            }
                            <SimpleTextArea
                                value={newComment}
                                width={'100%'}
                                onChange={(e) => {
                                    setNewComment(e.currentTarget.value)
                                }} />
                            <div className={s.btns}>
                                <ListItem
                                    button
                                    style={{
                                        background: '#212529',
                                        borderRadius: 5,
                                        width: 105,
                                        height: 37,
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: 14,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        applicationIdState && requestSendComment({
                                            id_task: applicationIdState,
                                            name: newComment
                                        }, () => {
                                            openNotification({
                                                type: 'success',
                                                message: 'Комментарий отправлен'
                                            })
                                            requestTaskComments({
                                                id_task: applicationIdState,
                                                count: 1354,
                                                offset: 0
                                            })
                                            setNewComment('')
                                        })
                                    }}
                                >
                                    Отправить
                                </ListItem>
                                <ListItem
                                    button
                                    style={{
                                        background: 'white',
                                        borderRadius: 5,
                                        width: 105,
                                        height: 37,
                                        color: 'black',
                                        fontWeight: 500,
                                        fontSize: 14,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid #878787',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setNewComment('')
                                    }}
                                >
                                    Отменить
                                </ListItem>
                            </div>
                        </div>}
                        {subPage.id === 1 && <div className={s.history_block}>
                            <HistoryTableContainer taskId={applicationIdState || 0} />
                        </div>}
                        {subPage.id === 2 && <div className={s.actions_block}>
                            <div className={s.actionsFilters}>
                                <input type="text" value={searchValue}
                                    className={s.input}
                                    onChange={(e) => setSearchValue(e.currentTarget.value)}
                                    placeholder={'Поиск...'}
                                />
                                <RangeDatePicker
                                    width={isMobile ? '100%' : 450}
                                    value={undefined}
                                    onChange={(value: Moment[]) => {
                                        setActionDateFrom(value[0].format('DD.MM.YYYY'))
                                        setActionDateTo(value[1].format('DD.MM.YYYY'))
                                    }} />
                                <ListItem
                                    button
                                    onClick={requestTaskActionsHandler}
                                    style={{
                                        width: isMobile ? '100%' : 95,
                                        height: 38,
                                        marginTop: 18,
                                        background: 'black',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 5,
                                        color: 'white'
                                    }}
                                >
                                    Поиск
                                </ListItem>
                            </div>
                            {/*<DatePickerComponent value={}/>*/}

                            <ActionsTableContainer
                                id_device={applicationInfo?.device?.id}
                                id_system={applicationInfo?.system?.id}
                                searchValue={searchValue}
                                dateFrom={actionDateFrom}
                                dateTo={actionDateTo}
                            />
                        </div>}
                    </div>
                </div>}
        </div>
    )
})

